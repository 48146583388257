<template>
  <div class="card">
    <div class="card-header d-flex">
      <div>
        {{ type }} <br>
        <small>{{ description }}</small>
      </div>
      <form @submit.prevent="submit" class="ml-auto">
        <div class="input-group">
          <input
            v-model="url"
            type="text"
            required="required"
            placeholder="URL"
            name="url"
            class="form-control"
            :class="{'is-invalid' : error}"
          />
          <div class="input-group-append">
            <button type="submit" class="btn btn-success"
                :disabled="submitting">
                New
            </button>
          </div>
          <div class="invalid-feedback">
            {{ error }}
          </div>
        </div>
      </form>
    </div>
    <div class="card-body p-0">
        <ul class="list-group list-group-flush" v-if="webhooks.length">
            <li v-for="(hook, i) in webhooks" :key="hook.id"
                class="list-group-item">
                <Hook :hook="hook" @delete="remove(i)" />
            </li>
        </ul>
        <ul class="list-group list-group-flush" v-else>
            <li class="list-group-item">
                <span class="text-muted">No webhooks</span>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
import Hook from "./Hook.vue";

const TYPES = {
    'authorization.created' : 'Triggered when a new authorization is attempted.',
    'transaction.created' : 'Triggered when a new transaction is created.',
    'transaction.chargeback' : 'Triggered when a transaction has a chargeback against it.',
    'transaction.retrieval' : 'Triggered when a bank requests more information about a transaction, often precedes a chargeback',
    'transaction.ach_reject' : 'Triggered when a ACH transaction fails due to insufficient funds',
    'transaction.updated' : 'Triggered when a transaction is updated',
    'payer.created' : 'Triggered when a new payer is created',
    'payer.updated' : 'Triggered when a payer is updated',
};

export default {
  name: "Webhooks",
  components: {
    Hook
  },
  props: {
    type: {
      type: String,
      default: null
    },
    hooks: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      webhooks: this.hooks,
      url: null,
      error: null,
      submitting: false,
    };
  },
  computed: {
      description() {
          return TYPES[this.type];
      },
  },
  methods: {
    remove(index) {
      this.webhooks.splice(index, 1);
    },
    submit() {
        if(!this.url) return;

        this.submitting = true;
        this.error = null;

        axios.post('./webhooks', {
            event_type: this.type,
            url: this.url,
        })
        .then((res) => {
            this.url = null;
            this.webhooks.push(res.data);
        })
        .catch((e) => {
            console.log(e);
            if(e.response) {
                if (e.response.status == 422) {
                    this.error = e.response.data.errors.url[0];
                }
            }
        })
        .then(() => {
            this.submitting = false;
        });
    }
  }
};
</script>
