<template>
    <div>
        <h1>Create Merchant</h1>
        <pre><code>{{JSON.stringify(merchant, null, 2)}}</code></pre>
        <div class="form-group row">
            <label class="col-3">Business Name</label>
            <div class="col">
                <input class="form-control" placeholder="Business Name" v-model="merchant.name">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-3">Doing Business As</label>
            <div class="col">
                <input class="form-control" placeholder="Doing Business As" v-model="merchant.dba">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-3">Business Email</label>
            <div class="col">
                <input type="email" class="form-control" placeholder="Business Email" v-model="merchant.email">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'create-merchant',
    data() {
        return {
            merchant: {
                name: null,
                dba: null,
                email: null,
                phone: null,
                website: null,
                monthly_volume: 1500000,
                average_ticket: 7500,
                high_ticket: 30000,
                ein: null,
                mcc: '5933',
                description: null,
                address: {
                    street: null,
                    city: null,
                    state: null,
                    postal: null,
                    country: null,
                },
                mailing_address: {
                    street: null,
                    city: null,
                    state: null,
                    postal: null,
                    country: null,
                },
            },
        };
    },
}
</script>

