<template>
  <nav class="d-flex">
    <div class="p-2 mr-3">{{from}} to {{to}} of {{total}}</div>
    <ul class="pagination mb-0">
      <li class="page-item" :class="{disabled: current_page == 1}">
        <a class="page-link" role="button" @click="changePage(1)">
          <i class="fad fa-chevron-double-left"></i>
        </a>
      </li>
      <li class="page-item" :class="{disabled: current_page == 1}">
        <a class="page-link" role="button" @click="changePage(current_page - 1)">
          <i class="fad fa-chevron-left"></i>
        </a>
      </li>
      <!-- First -->
      <li
        class="page-item"
        v-for="page in pages"
        :key="`page-${page}`"
        :class="{active: page == current_page}"
      >
        <a class="page-link" role="button" @click="changePage(page)">{{page}}</a>
      </li>
      <!-- Last -->
      <li class="page-item" :class="{disabled: current_page == last_page}">
        <a class="page-link" role="button" @click="changePage(current_page + 1)">
          <i class="fad fa-chevron-right"></i>
        </a>
      </li>
      <li class="page-item" :class="{disabled: current_page == last_page}">
        <a class="page-link" role="button" @click="changePage(last_page)">
          <i class="fad fa-chevron-double-right"></i>
        </a>
      </li>
      <select class="form-control ml-3" :value="+per_page" @change="changePerPage($event.target.value)">
        <option v-for="(opt, i) in per_page_options" :key="`per_page-${i}`" :value="opt">{{opt}}</option>
      </select>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    current_page: {},
    per_page: {},
    per_page_options: {
      default: () => [15, 25, 50]
    },
    from: {},
    to: {},
    total: {},
    last_page: {
      default: 0
    },
    max_pages: {
      default: 10
    }
  },
  computed: {
    all_pages() {
      // array or numbers from 1 to last_page
      return [...new Array(this.last_page + 1).keys()].slice(1);
    },
    pages() {
      let pages = this.all_pages;

      if (pages.length > this.max_pages) {
        let diff = this.max_pages / 2;
        let diffFirst = this.current_page - pages[0];
        let diffLast = this.current_page - pages[pages.length - 1];

        if (diffFirst < diff) {
          return pages.slice(0, this.max_pages);
        } else if (diffLast >= -diff) {
          return pages.slice(-this.max_pages);
        } else {
          return pages.filter(page => {
            let diffPage = this.current_page - page;
            if (diffPage < 0) {
              return Math.abs(diffPage) <= diff;
            } else {
              return diffPage < diff;
            }
          });
        }
      }

      return pages;
    }
  },
  methods: {
    changePage(page) {
      if (page == this.current_page) return;
      this.$emit("page", page);
    },
    changePerPage(page) {
      this.$emit("per_page", page);
    }
  }
};
</script>
