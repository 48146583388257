<template>
  <div class="table-responsive">
    <table class="table table-sm mb-3" :class="{'table-hover': user_url}">
      <thead>
          <!-- Columns -->
        <tr>
          <th scope="col" role="button" @click="setSortBy('id')" :class="{'text-primary': sort_by == 'id'}">
                <table-sort-icon field="id" :current="sort_by" :desc="sort_desc" icon="numeric"></table-sort-icon>
              ID
          </th>
          <th scope="col" role="button" @click="setSortBy('name')" :class="{'text-primary': sort_by == 'name'}">
                <table-sort-icon field="name" :current="sort_by" :desc="sort_desc" icon="alpha"></table-sort-icon>
              Name
          </th>
          <th scope="col" role="button" @click="setSortBy('email')" :class="{'text-primary': sort_by == 'email'}">
                <table-sort-icon field="email" :current="sort_by" :desc="sort_desc" icon="alpha"></table-sort-icon>
              Email
          </th>
          <th scope="col" role="button" @click="setSortBy('active')" :class="{'text-primary': sort_by == 'active'}">
                <table-sort-icon field="active" :current="sort_by" :desc="sort_desc" icon="amount"></table-sort-icon>
              Active
          </th>
          <th scope="col" role="button" @click="setSortBy('created_at')" :class="{'text-primary': sort_by == 'created_at'}">
                <table-sort-icon field="created_at" :current="sort_by" :desc="sort_desc" icon="numeric"></table-sort-icon>
              Created At
          </th>
        </tr>
        <!-- Filters -->
        <tr class="filters">
          <td>
            <input type="text" class="form-control" v-model="filters.id.value" @change="getData"
                placeholder="Search ID"/>
          </td>
          <td>
            <input type="text" class="form-control" v-model="filters.name.value" @change="getData"
                placeholder="Search Name"/>
          </td>
          <td>
            <input type="text" class="form-control" v-model="filters.email.value" @change="getData"
                placeholder="Search Email"/>
          </td>
          <td>
            <select v-model="filters.active.option" class="form-control" @change="getData">
              <option
                v-for="(opt, i) in filters.active.options"
                :key="`active-opt-${i}`"
                :value="opt"
              >{{opt}}</option>
            </select>
          </td>
          <td>
            <input
              type="text"
              class="form-control"
              v-model="filters.created_at.value"
              @change="getData"
                placeholder="Search Created At"/>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr class="loading-overlay" v-if="loading">
          <span class="my-auto">
            <i class="fa-4x fa-spin fad fa-spinner"></i>
          </span>
        </tr>
        <tr v-for="(row, i) in rows" :key="`row-${i}`">
          <td>
              <a role="button" @click="goToUser(row.id)">
                  <i class="fad fa-link mr-2"></i>
                  {{row.id}}
                </a>
          </td>
          <td>{{row.email}}</td>
          <td>{{row.name}}</td>
          <td>
              <i class="fad mr-1" :class="{'fa-check-circle text-success': row.active, 'fa-times-circle text-danger': !row.active}"></i>
              {{row.active ? 'active' : 'inactive'}}
          </td>
          <td>{{row.created_at}}</td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex">
        <paginator
          :max-pages="10"
          :last_page="last_page"
          :from="from"
          :to="to"
          :total="total"
          :per_page="per_page"
          @per_page="setPerPage"
          :current_page="current_page"
          @page="setCurrentPage"
        ></paginator>
        <div v-if="report_url" class="ml-auto">
          <a :href="full_report_url" target="_BLANK" class="btn btn-primary mb-0">
            <i class="fad fa-file-excel"></i> Download
          </a>
        </div>
    </div>
  </div>
</template>

<script>
import Table from "./Table.vue";
import Paginator from "./Paginator.vue";
import TableSortIcon from "./Sort.vue";
import buildURL from "axios/lib/helpers/buildURL";

export default {
  name: "UserTable",
  extends: Table,
  components: {
    Paginator,
    TableSortIcon,
  },
  props: {
    report_url: {},
    user_url: {}
  },
  data() {
    return {
      loading: true,
      sort_by: 'created_at',
      sort_dir: 'asc',
      filters: {
        id: {
          value: ""
        },
        name: {
          value: ""
        },
        email: {
          value: ""
        },
        active: {
          option: "active",
          options: [
              "any",
              "active",
              "inactive",
          ],
        },
        created_at: {
          value: ""
        }
      },
      rows: null,
      current_page: 1,
      per_page: 15,
      from: 1,
      to: 15,
      last_page: 1,
      total: 0
    };
  },
  computed: {
    full_report_url() {
      if (!this.report_url) {
        return "";
      }

      return buildURL(this.report_url, { filters: this.prepareFilters() });
    }
  },
  methods: {
      goToUser(id) {
          if (!this.user_url) {
              return;
          }
          window.location = this.user_url + `/${id}`;
      },
    prepareFilters() {
      let filters = this.getFilters();


      if (this.filters.active.option !== "any") {
        if(this.filters.active.option === 'active') {
            filters['active'] = ['=', true];
        } else {
            filters['active'] = ['=', false];
        }
      }

      return filters;
    }
  }
};
</script>
