<template>
  <li class="list-group-item method-item">
    <div v-if="method.name">
      <span class="text-muted">Method Name:</span>
      {{method.name}}
    </div>
    <div class="text-muted">Token: {{method.id}}</div>
    <div class="d-flex">
      <i class="fa-3x mr-3" :class="icon"></i>
      <div>
        <span class="text-muted">Account Name:</span> {{method.account_name}} <br/>
        <span class="text-muted">Account Number:</span> {{account}}  <br />
        <template v-if="exp">
            <span class="text-muted">Exp:</span>
            {{exp}}
        </template>
        <template v-if="method.address">
            <span class="text-muted" >Street:</span> {{method.address.street}}
            <span class="text-muted" >City:</span> {{method.address.city}}
            <span class="text-muted" >State:</span> {{method.address.state}}
            <span class="text-muted" >Postal:</span> {{method.address.postal}}
            <span class="text-muted" >Country:</span> {{method.address.country}}
        </template>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: "MethodListItem",
  props: {
    method: {
      type: Object,
      required: true
    }
  },
  computed: {
    account() {
        return 'X'.repeat(4) + this.method.last_four;
    },
    exp() {
        if (!this.method.exp) return null;
        return this.method.exp.toString()
            .padStart(4, '0')
            .match(/.{1,2}/g)
            .join('/');
    },
    icon() {
      let icon = "fad fa-credit-card";

      if (this.method.type == "ach") {
        icon = "fad fa-money-check";
      } else if (this.method.type == "credit") {
        if (this.method.sub_type == "visa") {
          icon = "fab fa-cc-visa";
        } else if (this.method.sub_type == "mastercard") {
          icon = "fab fa-cc-mastercard";
        } else if (this.method.sub_type == "discover") {
          icon = "fab fa-cc-discover";
        } else if (this.method.sub_type == "amex") {
          icon = "fab fa-cc-amex";
        } else if (this.method.sub_type == "jcb") {
          icon = "fab fa-cc-jcb";
        } else if (this.method.sub_type == "diners") {
          icon = "fab fa-cc-diners-club";
        }
      }

      return icon;
    }
  }
};
</script>
