<template>
  <div class="table-responsive">
    <table class="table table-sm mb-3" :class="{'table-hover': invoice_url}">
      <thead>
          <!-- Columns -->
        <tr>
          <th scope="col" role="button" @click="setSortBy('number')" :class="{'text-primary': sort_by == 'number'}">
                <table-sort-icon field="number" :current="sort_by" :desc="sort_desc" icon="numeric"></table-sort-icon>
              Name
          </th>
          <th>
              Total
          </th>
          <th scope="col">
              Description
          </th>
          <th scope="col" role="button" @click="setSortBy('created_at')" :class="{'text-primary': sort_by == 'created_at'}">
                <table-sort-icon field="created_at" :current="sort_by" :desc="sort_desc" icon="numeric"></table-sort-icon>
              Created At
          </th>
        </tr>
        <!-- Filters -->
        <tr class="filters">
          <td>
            <input type="text" class="form-control" v-model="filters.number.value" @change="getData"
                placeholder="Search Number"/>
          </td>
          <td>
          </td>
          <td>
            <input type="text" class="form-control" v-model="filters.description.value" @change="getData"
                placeholder="Search Description"/>
          </td>
          <td>
            <input
              type="text"
              class="form-control"
              v-model="filters.created_at.value"
              @change="getData"
                placeholder="Search Created At"/>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr class="loading-overlay" v-if="loading">
          <span class="my-auto">
            <i class="fa-4x fa-spin fad fa-spinner"></i>
          </span>
        </tr>
        <tr v-for="(row, i) in rows" :key="`row-${i}`">
          <td class="text-uppercase">
              <a role="button" @click="goToInvoice(row.id)">
                  <i class="fad fa-link mr-2"></i>
                  {{row.number}}
                </a>
          </td>
          <td>{{centsToDollars(row.total)}}</td>
          <td>{{row.description}}</td>
          <td>{{row.created_at}}</td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex">
        <paginator
          :max-pages="10"
          :last_page="last_page"
          :from="from"
          :to="to"
          :total="total"
          :per_page="per_page"
          @per_page="setPerPage"
          :current_page="current_page"
          @page="setCurrentPage"
        ></paginator>
        <div v-if="report_url" class="ml-auto">
          <a :href="full_report_url" target="_BLANK" class="btn btn-primary mb-0">
            <i class="fad fa-file-excel"></i> Download
          </a>
        </div>
    </div>
  </div>
</template>

<script>
import Table from "./Table.vue";
import Paginator from "./Paginator.vue";
import TableSortIcon from "./Sort.vue";
import buildURL from "axios/lib/helpers/buildURL";
import { centsToDollars } from "../../helpers";

export default {
  name: "InvoiceTable",
  extends: Table,
  components: {
    Paginator,
    TableSortIcon,
  },
  props: {
    report_url: {},
    invoice_url: {}
  },
  data() {
    return {
      loading: true,
      sort_by: 'created_at',
      sort_dir: 'asc',
      filters: {
        number: {
          value: ""
        },
        description: {
          value: ""
        },
        created_at: {
          value: ""
        }
      },
      rows: null,
      current_page: 1,
      per_page: 15,
      from: 1,
      to: 15,
      last_page: 1,
      total: 0
    };
  },
  computed: {
    full_report_url() {
      if (!this.report_url) {
        return "";
      }

      return buildURL(this.report_url, { filters: this.prepareFilters() });
    }
  },
  methods: {
    centsToDollars(val) {
      return centsToDollars(val);
    },
    goToInvoice(id) {
        if (!this.invoice_url) {
            return;
        }
        window.location = this.invoice_url + `/${id}`;
    },
    prepareFilters() {
      return this.getFilters();
    }
  }
};
</script>
