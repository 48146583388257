<template>
    <i class="fad"
        :class="classes"></i>
</template>

<script>
export default {
    name: 'TableSort',
    props: {
        field: {
            type: String,
            required: true,
        },
        current: {
            type: String,
        },
        desc: {
            type: Boolean,
            default: true,
        },
        icon: {
            type: String,
            default: 'amount',
            validator: (val) => {
                return ['alpha', 'amount', 'numeric', 'shapes', 'size'].indexOf(val) !== -1;
            }
        },
    },
    computed: {
        classes() {
            if (this.current != this.field) {
                return [`fa-sort-${this.icon}-down`];
            }

            const dir = this.desc ? 'up' : 'down';

            return [`fa-sort-${this.icon}-${dir}`];
        }
    }
}
</script>
