<template>
  <li class="list-group-item api-key-item" v-if="key">
    <div class="details">
      Name: {{key.name}} <br/>
      Key: {{key.id}} <br/>
      Created At: {{key.created_at}} <br/>
      Updated At: {{key.updated_at}}
    </div>
    <div class="actions" v-if="url">
      <button class="btn btn-primary mb-0" alt="regen key" @click="refreshKey">
        <i class="fad fa-sync-alt"></i>
      </button>
      <button class="btn btn-outline-dark mb-0" alt="access logs" @click="goToLogs">
        <i class="fad fa-file-alt"></i>
      </button>
      <button class="btn btn-danger mb-0" alt="delete key" @click="deleteKey">
        <i class="fad fa-trash-alt"></i>
      </button>
    </div>
  </li>
</template>

<script>
export default {
  name: "ApiKeyListItem",
  props: {
    apiKey: {
      type: Object,
      required: true
    },
    url: {
        type: String,
    }
  },
  data() {
    return {
      key: this.apiKey
    };
  },
  methods: {
    goToLogs() {
        window.location = `${this.url}/${this.key.id}/logs`;
    },
    refreshKey() {
      return axios.patch(`${this.url}/${this.key.id}/refresh`).then(({ data }) => {
        this.key = data;
      });
    },
    deleteKey() {
      return axios.delete(`${this.url}/${this.key.id}`).then((res) => {
        this.key = null;
      });
    }
  }
};
</script>
