<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-4">
        <datepicker v-model="from" input-class="form-control"></datepicker>
      </div>
      <div class="col-12 col-md-4">
        <datepicker v-model="to" input-class="form-control"></datepicker>
      </div>
      <div class="col-12 col-md-4">
        <select v-model="interval" class="form-control">
          <option v-for="i in interval_options" :key="i" :value="i">{{i}}</option>
        </select>
      </div>
    </div>
    <div :styles="styles" :class="cssClasses">
      <canvas ref="canvas" :id="chartId" :width="width" :height="height"></canvas>
    </div>
  </div>
</template>

<script>
import { Line } from "vue-chartjs";
import Datepicker from "vuejs-datepicker";
import { centsToDollars } from "../../helpers";

export default {
  name: "TransactionsTimeline",
  extends: Line,
  components: {
    Datepicker
  },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      from: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000), // a week ago
      to: new Date(),
      interval: "day",
      interval_options: ["hour", "day", "week", "month"],
      chart_data: [],
      colors: {
        blueGradient: null,
        greenGradient: null
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          position: 'average',
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function(tooltipItem, data) {
                let label = data.datasets[tooltipItem.datasetIndex].label || '';

                if (label) {
                    label += ': ';
                }

                if (tooltipItem.datasetIndex == 1) {
                    return label + centsToDollars(tooltipItem.value);
                }

                return label + tooltipItem.value;
            },
            footer: function(tooltipItems, data) {
                const i = tooltipItems[0].index;
                const count = Math.max(parseInt(data.datasets[0].data[i].y), 0);
                const amount = Math.max(parseInt(data.datasets[1].data[i].y), 0);
                return 'Avg: ' + centsToDollars((amount / count).toFixed(0));
            }
          }
        },
        hover: {
            mode: 'index',
            intersect: true,
        },
        scales: {
          xAxes: [
            {
              id: "date",
              type: "time",
              ticks: {
                maxTicksLimit: 10
              },
              gridLines: {
                drawOnChartArea: false
              }
            }
          ],
          yAxes: [
            {
              id: "amount",
              type: "linear",
              gridLines: {
                drawOnChartArea: false
              },
              ticks: {
                maxTicksLimit: 5,
                min: 0,
                beginAtZero: true,
                callback: (value, index, values) => {
                  return centsToDollars(value);
                }
              }
            },
            {
              id: "count",
              type: "linear",
              gridLines: {
                drawOnChartArea: false
              },
              ticks: {
                maxTicksLimit: 5,
                suggestedMax: 50,
                stepSize: 10,
                beginAtZero: true
              }
            }
          ]
        }
      }
    };
  },
  mounted() {
    if (!this.url) {
      return;
    }
    const ctx = this.$refs.canvas.getContext("2d");

    this.colors.blueGradient = ctx.createLinearGradient(
      0,
      0,
      0,
      this.$refs.canvas.height
    );
    this.colors.blueGradient.addColorStop(0.5, "rgba(50, 176, 255, 0.25)");
    this.colors.blueGradient.addColorStop(1, "rgba(0, 99, 255, 1)");

    this.colors.greenGradient = ctx.createLinearGradient(
      0,
      0,
      0,
      this.$refs.canvas.height
    );
    this.colors.greenGradient.addColorStop(0, "rgba(56, 193, 114, 0.25)");
    this.colors.greenGradient.addColorStop(0.75, "rgba(105, 243, 163, 1)");

    this.getData();
  },
  watch: {
    from: function() {
      this.getData();
    },
    to: function() {
      this.getData();
    },
    interval: function() {
      this.getData();
    }
  },
  methods: {
    getData() {
      return axios
        .get(this.url, {
          params: {
            from: this.from,
            to: this.to,
            interval: this.interval
          }
        })
        .then(res => {
          this.chart_data = this.transformResponse(res.data);
          this.renderChart(this.chart_data, this.options);
        });
    },
    transformResponse(data) {
      let dates = Object.keys(data).sort((a, b) => (a > b ? 1 : -1));
      let amounts = [];
      let counts = [];
      let max_count = 0;

      dates.forEach(date => {
        if (data[date].count > max_count) {
          max_count = data[date].count;
        }
        amounts.push({
          x: date,
          y: data[date].amount
        });
        counts.push({
          x: date,
          y: data[date].count
        });
      });

      this.options.scales.yAxes[1].ticks.suggestedMax = max_count * 2;

      return {
        datasets: [
          {
            label: "Transaction Count",
            yAxisID: "count",
            backgroundColor: this.colors.blueGradient,
            data: counts,
            lineTension: 0.25
          },
          {
            label: "Transaction Volume",
            yAxisID: "amount",
            backgroundColor: this.colors.greenGradient,
            data: amounts,
            lineTension: 0.25
          }
        ]
      };
    }
  }
};
</script>

<style>
</style>
