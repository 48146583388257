<template>
    <div class="row">
        <div class="col-12 col-lg-2">
            <span class="badge p-2 mb-1" :class="`badge-${requestStatus}`">
                {{ request.status }} | {{ request.message }}
            </span> <br>
            {{ request.created_at }}
        </div>
        <div class="col-12 col-lg-10">
            <pre v-highlightjs="JSON.stringify(request.payload, null, 2)"><code class="json"></code></pre>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WebhookRequest',
    props: {
        request: {
            type: Object,
            default: () => {},
        }
    },
    computed: {
        requestStatus() {
            switch (this.request.status / 100) {
                case 2:
                    return 'success';
                case 3:
                    return 'warning';
                case 4:
                    return 'secondary';
                default:
                    return 'danger';
            }
        }
    }
}
</script>
