<template>
    <div>
        <div v-if="'value' in content"
            class="form-group">
            <input v-model="content.value" :type="content.type"
                class="form-control" :class="{'is-invalid' : errors[content.name]}"
                :placeholder="content.name"
                :name="content.name"
                @input="handleInput"/>
            <span class="invalid-feedback" v-if="errors[content.name]">
                <span v-for="(error, i) in errors[content.name]" :key="`${content.name}-error-${i}`">
                    {{error}}
                </span>
            </span>
        </div>
        <div v-else :class="marginClass">
            {{groupName}}
            <template v-for="(val, key) in content">
                <app-input v-model="content[key]"
                    :key="val.name"
                    @input="handleInput"
                    :errors="errors"
                    :depth="depth + 1"></app-input>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    name: 'app-input',
    props: {
        value: {},
        errors: {},
        depth: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            content: this.value,
        };
    },
    computed: {
        groupName() {
            if('value' in this.content) return null;

            const key = Object.keys(this.content)[0];

            return this.content[key].name.split('.')[this.depth];
        },
        marginClass() {
            return `ml-${3 * this.depth}`;
        }
    },
    methods: {
        handleInput(e) {
            this.$emit('input', this.content);
        }
    }
}
</script>
