<template>
  <label @click.prevent="toggleCheck" class="checkbox">
    <input type="checkbox"
        :checked="local_value"
        :name="name"/>
    <i class="check-icon fad fa-2x"
        :class="{'fa-square': !local_value, 'fa-check-square': local_value}"></i>
    <span class="check-label">
        <slot></slot>
    </span>
  </label>
</template>

<style lang="scss" scoped>
.checkbox {
    margin-bottom: 1rem;
    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
}
.check-label {
    line-height: 1rem;
}
.check-icon {
    padding: 0.375rem 0.75rem;
}
</style>

<script>
export default {
  props: {
    checked: {
        type: Boolean,
        default: false,
    },
    value: {
        type: Boolean,
        default: false,
    },
    name: {
        type: String,
    },
  },
  data() {
      return {
          local_value: this.value || this.checked,
      };
  },
  watch: {
      value: function (n) {
          this.$emit('changed', n);
      },
  },
  methods: {
    toggleCheck() {
        this.local_value = !this.local_value;
    },
  }
};
</script>
