<template>
  <form action method="post">
    <input type="hidden" name="_token" v-model="csrf" />

    <template v-for="(field, key) in fields">
        <app-input v-model="fields[key]" :key="key" :errors="errors"></app-input>
    </template>

    <button type="submit" class="btn btn-primary btn-block">Submit</button>
  </form>
</template>

<script>
export default {
  name: "app-form",
  props: {
    csrf: String,
    rules: Object | Array,
    old: Object | Array,
    errors: Object | Array,
    values: Object | Array
  },
  mounted() {
    if (this.rules) this.parseRules();
  },
  data() {
    return {
      fields: {}
    };
  },
  computed: {
      output() {
          return this.fields
      }
  },
  methods: {
    parseRules() {
      this.fields = this.unflatten(this.mapRules(this.rules));
      Object.keys(this.fields).forEach(key => this.mapField);

      this.$forceUpdate();
    },
    mapRules(input) {
        const rules = Object.assign({}, input);

        Object.keys(rules).forEach((key) => {
            rules[key] = {
                name: key,
                value: this.old[key.replace('.', '_')] || null,
                rules: rules[key],
            };
        });

        return rules;
    },
    unflatten(data) {
      const result = {};
      for (var i in data) {
        var keys = i.split(".");
        keys.reduce(function(r, e, j) {
          return (
            r[e] ||
            (r[e] = isNaN(Number(keys[j + 1]))
              ? keys.length - 1 == j
                ? data[i]
                : {}
              : [])
          );
        }, result);
      }
      return result;
    }
  }
};
</script>
