<template>
<div class="row">
    <div class="col-12 col-md-6">
        <code>POST: </code> {{ hook.url }}
    </div>
    <div class="col-12 col-md-6 text-right">
        <button class="btn btn-danger" @click="sendDelete">
        delete
        </button>
        <button class="btn btn-primary" @click="getHistory">
        history
        </button>
    </div>
    <div class="col-12 collapse" v-if="requests" :class="{show: show_requests}">
        <ul class="list-group list-group-flush" v-if="requests.length">
            <li v-for="req in requests" :key="req.id"
                class="list-group-item">
                <request :request="req"/>
            </li>
        </ul>

        <ul class="list-group list-group-flush" v-else>
            <li class="list-group-item">
                <span class="text-muted">
                    This webhook hasn't been triggered recently, logs are kept for a maximum of 5 days.
                </span>
            </li>
        </ul>

    </div>
</div>
</template>

<script>
import Request from './Request.vue';

export default {
  name: 'Hook',
  components: {
      Request,
  },
  props: {
    hook: {
      type: Object,
      default: null,
    }
  },
  data() {
      return {
          show_requests: false,
          requests: null,
      }
  },
  methods: {
      sendDelete() {
          axios.delete(`webhooks/${this.hook.id}`)
            .then(() => {
                this.$emit('delete');
            })
            .catch((e) => {
                console.log(e);
            });
      },
      getHistory() {
          if (this.requests === null) {
            axios.get(`webhooks/${this.hook.id}/requests`)
                .then((res) => {
                    this.requests = res.data;
                    this.show_requests = true;
                })
                .catch((e) => {
                    console.log(e);
                });
          } else {
              this.show_requests = !this.show_requests;
          }
      },
  }
}
</script>
