<template>
  <div class="table-responsive">
    <table class="table table-sm mb-3" :class="{'table-hover': log_url}">
      <!-- Head -->
      <thead>
        <!-- Columns -->
        <tr>
          <th
            scope="col"
            role="button"
            @click="setSortBy('status')"
            :class="{'text-primary': sort_by == 'status'}"
          >
            <table-sort-icon
              class="mr-2"
              field="status"
              :current="sort_by"
              :desc="sort_desc"
              icon="numeric"
            ></table-sort-icon>Status
          </th>
          <th
            scope="col"
            role="button"
            @click="setSortBy('method')"
            :class="{'text-primary': sort_by == 'method'}"
          >
            <table-sort-icon
              class="mr-2"
              field="method"
              :current="sort_by"
              :desc="sort_desc"
              icon="alpha"
            ></table-sort-icon>Method
          </th>
          <th
            scope="col"
            role="button"
            @click="setSortBy('url')"
            :class="{'text-primary': sort_by == 'url'}"
          >
            <table-sort-icon
              class="mr-2"
              field="url"
              :current="sort_by"
              :desc="sort_desc"
              icon="alpha"
            ></table-sort-icon>URL
          </th>
          <th
            scope="col"
            role="button"
            @click="setSortBy('user_agent')"
            :class="{'text-primary': sort_by == 'user_agent'}"
          >
            <table-sort-icon
              class="mr-2"
              field="user_agent"
              :current="sort_by"
              :desc="sort_desc"
              icon="numeric"
            ></table-sort-icon>User Agent
          <th
            scope="col"
            role="button"
            @click="setSortBy('created_at')"
            :class="{'text-primary': sort_by == 'created_at'}"
          >
            <table-sort-icon
              class="mr-2"
              field="created_at"
              :current="sort_by"
              :desc="sort_desc"
              icon="numeric"
            ></table-sort-icon>Created At
          </th>
          </th>
        </tr>
        <!-- Filters -->
        <tr class="filters">
          <td>
            <input
              type="text"
              class="form-control"
              v-model="filters.status.value"
              @change="getData"
              placeholder="Search Status"
            />
          </td>
          <td>
            <select v-model="filters.method.value" class="form-control" @change="getData">
              <option
                v-for="(opt, i) in filters.method.options"
                :key="`method-opt-${i}`"
                :value="opt"
              >{{opt}}</option>
            </select>
          </td>
          <td>
            <input
              type="text"
              class="form-control"
              v-model="filters.url.value"
              @change="getData"
              placeholder="Search URL"
            />
          </td>
          <td>
            <input
              type="text"
              class="form-control"
              v-model="filters.user_agent.value"
              @change="getData"
              placeholder="Search User Agent"
            />
          </td>
          <td>
            <input
              type="text"
              class="form-control"
              v-model="filters.created_at.value"
              @change="getData"
              placeholder="Search Created At"
            />
          </td>
        </tr>
      </thead>
      <!-- Body -->
      <tbody>
        <tr class="loading-overlay" v-if="loading">
          <span class="my-auto">
            <i class="fa-4x fa-spin fad fa-spinner"></i>
          </span>
        </tr>
        <tr v-for="(row, i) in rows" :key="`row-${i}`">
          <td>
              {{row.status}}
          </td>
          <td>
              {{row.method}}
          </td>
          <td>
            <a role="button" @click="goToLog(row.id)">
              <i class="fad fa-link mr-2"></i>
              {{row.url}}
            </a>
          </td>
          <td>
              {{row.user_agent}}
          </td>
          <td>{{row.created_at}}</td>
        </tr>
      </tbody>
    </table>
    <!-- Footer -->
    <div class="d-flex">
      <paginator
        :max-pages="10"
        :last_page="last_page"
        :from="from"
        :to="to"
        :total="total"
        :per_page="per_page"
        @per_page="setPerPage"
        :current_page="current_page"
        @page="setCurrentPage"
      ></paginator>
      <div v-if="report_url" class="ml-auto">
        <a :href="full_report_url" target="_BLANK" class="btn btn-primary mb-0">
          <i class="fad fa-file-excel"></i> Download
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "./Table.vue";
import Paginator from "./Paginator.vue";
import TableSortIcon from "./Sort.vue";
import buildURL from "axios/lib/helpers/buildURL";

export default {
  name: "ApiLogTable",
  extends: Table,
  components: {
    Paginator,
    TableSortIcon
  },
  props: {
    report_url: {},
    log_url: {}
  },
  data() {
    return {
      loading: true,
      sort_by: "created_at",
      sort_dir: "asc",
      filters: {
        status: {
          value: ""
        },
        method: {
          options: [
            "any",
            "GET",
            "POST",
            "PUT",
            "PATCH",
            "DELETE"
          ],
          value: "any"
        },
        url: {
          value: "",
        },
        user_agent: {
          value: "",
        },
        created_at: {
          value: ""
        }
      },
      rows: null,
      current_page: 1,
      per_page: 15,
      from: 1,
      to: 15,
      last_page: 1,
      total: 0,
    };
  },
  computed: {
    full_report_url() {
      if (!this.report_url) {
        return "";
      }

      return buildURL(this.report_url, { filters: this.prepareFilters() });
    }
  },
  methods: {
    goToLog(id) {
      if (!this.log_url) {
        return;
      }
      window.location = this.log_url + `/${id}`;
    },
    prepareFilters() {
      let filters = this.getFilters();

      if (this.filters.method.value == "any") {
        delete filters.method;
      }

      return filters;
    }
  }
};
</script>
