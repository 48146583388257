<template>
    <div class="api_logs">
        <ul class="list-group logs">
            <li v-for="(l, i) in logs" :key="`api_log-${i}`"
                role="button"
                @click="log = l"
                class="list-group-item"
                :class="{active : log && l.id == log.id}">
                <span class="badge badge-pill mr-1"
                    :class="statusColor(l.status)">
                    {{l.status}}
                </span>
                <small>{{path(l.url)}}</small> <br>
                {{l.created_at}}
            </li>
        </ul>
        <div class="log-wrapper pl-3">
            <api-log v-if="log" :log="log"></api-log>
            <span v-else>
                Select a log entry to view
            </span>
        </div>
    </div>
</template>

<style lang="scss">
$list-width: 250px;
.api_logs {
    display: flex;
    position: relative;
    .logs {
        position: absolute;
        top:0;
        bottom:0;
        width: $list-width;
        overflow-y: scroll;
    }
    .log-wrapper {
        margin-left: $list-width;
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        min-height: 600px;
    }
}
</style>

<script>
import ApiLog from './ApiLog.vue';
export default {
    name: "ApiLogs",
    components: {
        ApiLog,
    },
    props: {
        logs: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            log: this.logs.length ? this.logs[0] : null,
        };
    },
    methods: {
        path(url) {
            let i = url.indexOf('api/');
            return url.substr(i);
        },
        statusColor(status) {
            if (!status) {
                return 'badge-secondary';
            }

            const level = +(''+status)[0];

            if (level == 2) {
                return 'badge-primary';
            } else if (level == 3) {
                return 'badge-warning';
            } else {
                return 'badge-danger';
            }
        }
    }
};

</script>
