<template>
    <div>
        Request
        <pre v-highlightjs="JSON.stringify(base, null, 2)"><code class="json"></code></pre>
        Headers
        <pre v-highlightjs="JSON.stringify(headers, null, 2)"><code class="json"></code></pre>
        Body
        <pre v-highlightjs="JSON.stringify(log.body, null, 2)"><code class="json"></code></pre>
        Response
        <pre v-highlightjs="JSON.stringify(log.response, null, 2)"><code class="json"></code></pre>
    </div>
</template>

<script>
export default {
    name: "ApiLog",
    props: {
        log: {
            type: Object,
            required: true,
        }
    },
    computed: {
        base() {
            const log = Object.assign({}, this.log);

            delete log.headers;
            delete log.body;
            delete log.response;

            return log
        },
        headers() {
            const headers = {};

            if (!this.log.headers) {
                return headers;
            }

            for (const key in this.log.headers) {
                if (this.log.headers.hasOwnProperty(key)) {
                    const element = this.log.headers[key];
                    headers[key] = element[0];
                }
            }

            return headers;
        }
    }
}
</script>
