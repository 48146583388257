<template>
  <div class="table-responsive">
    <table class="table table-sm mb-3" :class="{'table-hover': trans_url}">
      <!-- Head -->
      <thead>
        <!-- Columns -->
        <tr>
          <th
            scope="col"
            role="button"
            @click="setSortBy('id')"
            :class="{'text-primary': sort_by == 'id'}"
          >
            <table-sort-icon
              class="mr-2"
              field="id"
              :current="sort_by"
              :desc="sort_desc"
              icon="numeric"
            ></table-sort-icon>Transaction
          </th>
          <th
            scope="col"
            role="button"
            @click="setSortBy('invoice->number')"
            :class="{'text-primary': sort_by == 'invoice->number'}"
          >
            <table-sort-icon
              class="mr-2"
              field="invoice->number"
              :current="sort_by"
              :desc="sort_desc"
              icon="alpha"
            ></table-sort-icon>Invoice
          </th>
          <th
            scope="col"
            role="button"
            @click="setSortBy('amount')"
            :class="{'text-primary': sort_by == 'amount'}"
          >
            <table-sort-icon
              class="mr-2"
              field="amount"
              :current="sort_by"
              :desc="sort_desc"
              icon="numeric"
            ></table-sort-icon>Amount
          </th>
          <th scope="col">Status</th>
          <th
            scope="col"
            role="button"
            @click="setSortBy('created_at')"
            :class="{'text-primary': sort_by == 'created_at'}"
          >
            <table-sort-icon
              class="mr-2"
              field="created_at"
              :current="sort_by"
              :desc="sort_desc"
              icon="numeric"
            ></table-sort-icon>Created At
          </th>
        </tr>
        <!-- Filters -->
        <tr class="filters">
          <td>
            <input
              type="text"
              class="form-control"
              v-model="filters.id.value"
              @change="getData"
              placeholder="Search Transaction ID"
            />
          </td>
          <td>
            <input
              type="text"
              class="form-control"
              v-model="filters.invoice.value"
              @change="getData"
              placeholder="Search Invoice Number"
            />
          </td>
          <td>
            <input
              type="text"
              class="form-control"
              v-model="filters.amount.value"
              @change="getData"
              placeholder="Search Amount"
            />
          </td>
          <td>
            <select v-model="filters.status.option" class="form-control" @change="getData">
              <option
                v-for="(opt, i) in filters.status.options"
                :key="`status-opt-${i}`"
                :value="opt"
              >{{opt}}</option>
            </select>
          </td>
          <td>
            <input
              type="text"
              class="form-control"
              v-model="filters.created_at.value"
              @change="getData"
              placeholder="Search Created At"
            />
          </td>
        </tr>
      </thead>
      <!-- Body -->
      <tbody>
        <tr class="loading-overlay" v-if="loading">
          <span class="my-auto">
            <i class="fa-4x fa-spin fad fa-spinner"></i>
          </span>
        </tr>
        <tr v-for="(row, i) in rows" :key="`row-${i}`">
          <td>
            <a role="button" @click="goToTransaction(row.id)">
              <i class="fad fa-link mr-2"></i>
              {{row.id}}
            </a>
          </td>
          <td>
            <span v-if="row.invoice">
              <a
                data-toggle="modal"
                data-target="#invoiceModal"
                role="button"
                @click="setInvoice(row.invoice)"
              >
                <i class="fad fa-file-invoice mr-2"></i>
                {{row.invoice.number}}
              </a>
            </span>
          </td>
          <td>{{centsToDollars(row.amount)}}</td>
          <td>{{row.status}}</td>
          <td>{{row.created_at}}</td>
        </tr>
      </tbody>
    </table>
    <!-- Footer -->
    <div class="d-flex">
      <paginator
        :max-pages="10"
        :last_page="last_page"
        :from="from"
        :to="to"
        :total="total"
        :per_page="per_page"
        @per_page="setPerPage"
        :current_page="current_page"
        @page="setCurrentPage"
      ></paginator>
      <div v-if="report_url" class="ml-auto">
        <a :href="full_report_url" target="_BLANK" class="btn btn-primary mb-0">
          <i class="fad fa-file-excel"></i> Download
        </a>
      </div>
    </div>
    <!-- Invoice Modal -->
    <div class="modal fade" id="invoiceModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document" v-if="invoice">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Invoice #{{invoice.number}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body table-responsive">
              <template v-if="invoice.items && invoice.items.length">
                <h6>Items</h6>
                <table class="table">
                <thead>
                    <th scope="col">Name</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Price</th>
                    <th scope="col">Line Total</th>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in invoice.items" :key="`item-${i}`">
                    <td>{{item.name}}</td>
                    <td>{{item.quantity}}</td>
                    <td>{{centsToDollars(item.price)}}</td>
                    <td>{{centsToDollars(item.price * item.quantity)}}</td>
                    </tr>
                </tbody>
                </table>
              </template>
            <div v-if="invoice.discounts && invoice.discounts.length" class="table-responsive">
              <h5>Subtotal: {{centsToDollars(invoice.sub_total)}}</h5>
              <h6>Discounts</h6>
              <table class="table">
                <thead>
                  <th scope="col">Name</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Price</th>
                  <th scope="col">Line Total</th>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in invoice.discounts" :key="`discount-${i}`">
                    <td>{{item.name}}</td>
                    <td>{{item.quantity}}</td>
                    <td>{{centsToDollars(item.price)}}</td>
                    <td>{{centsToDollars(item.price * item.quantity)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h4>Total: {{centsToDollars(invoice.total)}}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { centsToDollars } from "../../helpers";
import Table from "./Table.vue";
import Paginator from "./Paginator.vue";
import TableSortIcon from "./Sort.vue";
import buildURL from "axios/lib/helpers/buildURL";

export default {
  name: "TransactionTable",
  extends: Table,
  components: {
    Paginator,
    TableSortIcon
  },
  props: {
    report_url: {},
    trans_url: {}
  },
  data() {
    return {
      loading: true,
      sort_by: "created_at",
      sort_dir: "asc",
      filters: {
        id: {
          value: ""
        },
        invoice: {
          value: ""
        },
        amount: {
          value: "",
          type: "dollars"
        },
        status: {
          options: [
            "any",
            "authorized",
            "captured",
            "voided",
            "settled",
            "refunded"
          ],
          option: "any"
        },
        created_at: {
          value: ""
        }
      },
      rows: null,
      current_page: 1,
      per_page: 15,
      from: 1,
      to: 15,
      last_page: 1,
      total: 0,
      invoice: null
    };
  },
  computed: {
    full_report_url() {
      if (!this.report_url) {
        return "";
      }

      return buildURL(this.report_url, { filters: this.prepareFilters() });
    }
  },
  methods: {
    setInvoice(invoice) {
      this.invoice = invoice;
    },
    goToTransaction(id) {
      if (!this.trans_url) {
        return;
      }
      window.location = this.trans_url + `/${id}`;
    },
    centsToDollars(val) {
      return centsToDollars(val);
    },
    createStatusQuery(filters, status) {
      if (status === "authorized") {
        filters["captured_at"] = ["=", null];
        filters["voided_at"] = ["=", null];
        filters["settled_at"] = ["=", null];
      } else if (status === "voided") {
        filters["voided_at"] = ["!=", null];
      } else if (status === "settled") {
        filters["settled_at"] = ["!=", null];
      } else if (status === "refunded") {
        filters["refunded_at"] = ["!=", null];
      } else if (status === "captured") {
        filters["captured_at"] = ["!=", null];
        filters["voided_at"] = ["=", null];
        filters["refunded_at"] = ["=", null];
        filters["settled_at"] = ["=", null];
      }
    },
    prepareFilters() {
      let filters = this.getFilters();

      if (filters.invoice) {
        filters["invoice->number"] = filters.invoice;
        delete filters.invoice;
      }

      if (this.filters.status.option !== "any") {
        this.createStatusQuery(filters, this.filters.status.option);
      }

      return filters;
    }
  }
};
</script>
