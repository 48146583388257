/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import VueHighlightJS from 'vue-highlightjs';

window.Vue = require('vue');

Vue.use(VueHighlightJS);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('create-merchant', require('./components/merchant/Create.vue').default);
Vue.component('app-form', require('./components/Form.vue').default);
Vue.component('app-input', require('./components/Input.vue').default);
Vue.component('app-checkbox', require('./components/Checkbox.vue').default);

// Tables
Vue.component('app-user-table', require('./components/tables/UserTable.vue').default);
Vue.component('app-merchant-table', require('./components/tables/MerchantTable.vue').default);
Vue.component('app-payer-table', require('./components/tables/PayerTable.vue').default);
Vue.component('app-trans-table', require('./components/tables/TransactionTable.vue').default);
Vue.component('app-invoice-table', require('./components/tables/InvoiceTable.vue').default);
Vue.component('app-log-table', require('./components/tables/ApiLogTable.vue').default);

// Charts
Vue.component('app-trans-timeline', require('./components/charts/TransactionsTimeline.vue').default);
Vue.component('app-api-timeline', require('./components/charts/ApiLogTimeline.vue').default);

// API Keys
Vue.component('app-api-key-li', require('./components/api_key/ApiKeyListItem.vue').default);

// API Logs
Vue.component('app-api-log', require('./components/api_key/ApiLog.vue').default);
Vue.component('app-api-logs', require('./components/api_key/ApiLogs.vue').default);

// Methods
Vue.component('app-method-li', require('./components/method/MethodListItem.vue').default);

Vue.component('app-webhooks', require('./components/webhook/Webhooks.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
