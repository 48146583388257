<script>
export default {
  name: "AppTable",
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      sort_by: null,
      sort_desc: true,
      filters: {},
      rows: null,
      current_page: 1,
      per_page: 15,
      from: 1,
      to: 15,
      last_page: 1,
      total: 0
    };
  },
  mounted() {
    if (!this.url) {
      return;
    }
    this.getData();
  },
  methods: {
      setSortBy(column) {
          if (this.sort_by == column) {
              this.sort_desc = !this.sort_desc;
          } else {
              this.sort_by = column;
              this.sort_desc = true;
          }
          this.getData();
      },
      setCurrentPage(page) {
          this.current_page = page;
          this.getData();
      },
      setPerPage(count) {
          this.current_page = 1;
          this.per_page = count;
          this.getData();
      },
    prepareFilters() {
      return this.getFilters();
    },
    getFilters() {
      let filters = {};
      Object.keys(this.filters).forEach(key => {
        if (this.filters[key].value) {
          let match = /([\>\=\<\!]*)?([\w\.\-%]*)/.exec(
            this.filters[key].value
          );
          let operator = match[1] || "=";
          let value = match[2];

          if (value) {
            if (value.indexOf("%") > -1) {
              operator = "like";
            }

            if (this.filters[key].type == "dollars") {
              value = parseFloat(value) * 100;
            }

            filters[key] = [operator, value];
          }
        }
      });

      if (Object.keys(filters).length) {
        return filters;
      }

      return {};
    },
    getData() {
      this.loading = true;
      return axios
        .get(this.url, {
          params: {
            page: this.current_page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            sort_dir: this.sort_desc ? 'desc' : 'asc',
            filters: this.prepareFilters()
          }
        })
        .then(res => {
          this.current_page = +res.data.current_page;
          this.per_page = +res.data.per_page;
          this.from = +res.data.from;
          this.to = +res.data.to;
          this.last_page = +res.data.last_page;
          this.total = +res.data.total;
          this.rows = res.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
